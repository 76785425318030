import React from "react";
import "./style.css"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Msgs from "./components/Msgs.js";
import NotFound from "./components/NotFound.js";
import ChatHook from './components/ChatHook.js'
import { FormMagnit } from "./components/MagnitForm/FormMagnit.js";

window.Telegram.WebApp.expand()
class App extends React.Component {
    
    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/messages" exact Component={Msgs} />
                    <Route path="/chat/:user_id/:chat_id" element={<ChatHook />} />
                    <Route path="/magnit/slotform" element={<FormMagnit />} />
                    <Route path='*' Component={NotFound} />
                </Routes>
            </Router>
        )
    }
}

export default App